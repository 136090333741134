<template>
  <div class="terms-of-service">
    <h3 class="zq-page-title">{{ texts.title }}</h3>
    <div class="form-content content">
      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card">
            <CCardBody>
              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.yourAcceptanceTitle }}</h2>
                <p class="terms-section--text">{{ texts.yourAcceptanceText }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.throughoutThisAgreementTitle }}</h2>
                <p class="terms-section--text" v-html="texts.throughoutThisAgreementText"></p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.descriptionTitle }}</h2>
                <p class="terms-section--text">{{ texts.descriptionText }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.userAccountsTitle }}</h2>
                <p class="terms-section--text">{{ texts.userAccountsTextP1 }}</p>
                <p class="terms-section--text">{{ texts.userAccountsTextP2 }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.modificationTitle }}</h2>
                <p class="terms-section--text">{{ texts.modificationText }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.grantTitle }}</h2>
                <p class="terms-section--text">{{ texts.grantTextP1 }}</p>
                <p class="terms-section--text">{{ texts.grantTextP2 }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.useTitle }}</h2>
                <p class="terms-section--text">{{ texts.useTextP1 }}</p>
                <p class="terms-section--text">{{ texts.useTextP2 }}</p>
                <p class="terms-section--text">{{ texts.useTextP3 }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.guidelinesTitle }}</h2>
                <p class="terms-section--text">{{ texts.guidelinesText }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.softwareTitle }}</h2>
                <p class="terms-section--text">{{ texts.softwareText }}</p>
              </section>

              <section class="terms-section">
                <h2 class="terms-section--title">{{ texts.taxesTitle }}</h2>
                <p class="terms-section--text">{{ texts.taxesText }}</p>
              </section>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import {termsOfService} from "@/config/pageTexts/termsOfService.json";

export default {
  data() {
    return {
      texts: {
        ...termsOfService
      },
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);
    }
  }
}
</script>

<style lang="scss">
.terms-of-service {
  height: 100%;
  flex: 2;

  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }

  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);

  }

  .zq--wizard-card {
    .card-body {
      border-top: none;
    }
  }
}
</style>
